import { login, logout, getInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { isHttp } from '@/utils/validate';
import { TOKEN_RESPONSE_FIELD } from '@/constants/token-constants';
import defaultAvatar from '@/assets/images/profile.png';

/**
 * 格式解析头像地址
 * @param avatar 头像路径
 * @returns url地址
 */
function parseAvatar(avatar) {
  if (!avatar) {
    return defaultAvatar;
  }
  if (isHttp(avatar)) {
    return avatar;
  }
  const baseApi = import.meta.env.VITE_APP_BASE_API;
  return `${baseApi}${avatar}`;
}

const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
  }),
  actions: {
    // 登录
    login(userInfo) {
      const account = userInfo.account.trim();
      const password = userInfo.password;
      const code = userInfo.code;
      const uuid = userInfo.uuid;
      return new Promise((resolve, reject) => {
        login(account, password)
          .then(res => {
            if (res.code === 200 && res.data) {
              // const token = res.data[TOKEN_RESPONSE_FIELD];
              const token = res.data.token;
              setToken(token);
              this.token = token;
              const data = {
                isManager: res.data.isManager,
                phone: res.data.phone,
                userName: res.data.name,
                role: res.data.role,
              }
              localStorage.setItem("user", JSON.stringify(data))
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 获取用户信息
    getInfo() {
      return new Promise((resolve, reject) => {
        // getInfo()
        //   .then(res => {
        //     if (res.code === 200 && res.data) {
        //       const { user, roles, permissions } = res.data;
        //       this.name = user.userName;
        //       this.avatar = parseAvatar(user.avatar);

        //       // 验证返回的roles是否是一个非空数组
        //       if (Array.isArray(roles) && roles.length > 0) {
        //         this.roles = roles;
        //         this.permissions = permissions;
        //       } else {
        //         this.roles = ['ROLE_DEFAULT'];
        //         this.permissions = [];
        //       }
        //       resolve(res);
        //       return;
        //     }
        //     // 网络错误时退出登录状态
        //     if (res.code === 500) {
        //       removeToken();
        //       window.location.reload();
        //       reject(res);
        //     }
        //   })
        //   .catch(error => {
        //     reject(error);
        //   });
        const data = JSON.parse(localStorage.getItem('user'))
        const { user, roles, permissions } = {
          roles: [],
          permissions: data.isManager ? ['system:user:admin'] : [],
          user: {
            phone: data.phone,
            userName: data.name,
          }
        };

        // 自定义的role，在user表的role字段
        if (data.role !== '普通员工') {
          permissions.push('leader');
        }

        this.name = user?.userName;
        this.avatar = parseAvatar(user?.avatar);

        // 验证返回的roles是否是一个非空数组
        if (Array.isArray(roles) && roles.length > 0) {
          this.roles = roles;
          this.permissions = permissions;
        } else {
          this.roles = ['ROLE_DEFAULT'];
          this.permissions = permissions;
        }
        resolve()
      });
    },
    // 退出系统
    logOut() {
      return new Promise(resolve => {
        logout(this.token).finally(() => {
          this.token = '';
          this.roles = [];
          this.permissions = [];
          localStorage.removeItem('user');
          removeToken();
          resolve();
        });
      });
    },
  },
});

export default useUserStore;
