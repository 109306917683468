// import request from '@/utils/request'

/**
 * 上报错误
 */
export function reportVueError(err, info) {
  console.error(err);
  console.log(info);

  if (window.location.host.indexOf('.linkedone.cn') === -1) {
    return;
  }

  let type = 'vueError';

  let url = window.location.href;

  let message = '';
  if (err) {
    message = err.stack ? err.stack : (err.name + ': ' + err.message);
  }
  if (info) {
    message += "\n\n" + info;
  }

  report(type, url, message);
}

/**
 * 上报普通错误
 */
export function reportCommonError(event) {
  console.error(event.message);

  if (window.location.host.indexOf('.linkedone.cn') === -1) {
    return;
  }

  let type = 'commonError';

  let url = event.filename;

  let message = event.message;
  if (event.lineno) {
    message += "\nline: " + event.lineno;
  }
  if (event.colno) {
    message += "\ncolumn: " + event.colno;
  }

  report(type, url, message);
}

function report(type, url, message) {
  let data = {
    type,
    url,
    message,
  };

  try {
    // 不用封装的request.js，那里有防止重复提交的代码
    // request({
    //   url: 'https://frontend.linkedone.cn/report/error',
    //   headers: {
    //     isToken: false
    //   },
    //   method: 'post',
    //   data: data,
    //   timeout: 1000
    // });

    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://frontend.linkedone.cn/report/error');
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.timeout = 1000;
    xhr.send(JSON.stringify(data));
  } catch (err) {
    // ignore
  }
}