import Cookies from 'js-cookie';
import { TOKEN_COOKIE } from '@/constants/token-constants';

export function getToken() {
  return Cookies.get(TOKEN_COOKIE) || '';
}

export function setToken(token) {
  return Cookies.set(TOKEN_COOKIE, token);
}

export function removeToken() {
  return Cookies.remove(TOKEN_COOKIE);
}
